export const uploadFiles = (files, companyId: string) => {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
    getFirebase: (...args: Array<any>) => any,
  ) => {
    const firebase = getFirebase();
    const now = new Date().toISOString();

    const promises = files.map((file) => {
      const fileStoragePath = `files/${companyId}/fields-import-requests/${now}/${file.name}`;

      const storageRef = firebase
        .storage()
        .ref()
        .child(fileStoragePath);

      return storageRef
        .put(file, {
          customMetadata: {
            uploadedByEmail: firebase.auth().currentUser.email,
            uploadedByName: firebase.auth().currentUser.displayName,
          },
        });    
    });

    return Promise.all(promises).catch((e) => console.error(e));
  };
};
