import { getUidToken } from "../../utils/auth";
import { BASE_API_URL } from "../../constants";

export const getCropRatingPdf = (cropRatingId: string) => {
  return getUidToken()
    .then((uidToken) => {
      return fetch(`${BASE_API_URL}/crop-ratings/${cropRatingId}/pdf`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${uidToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 400) {
            throw new Error(`Crop rating ${cropRatingId} type not supported!`);
          }
          if (res.status !== 200) {
            throw new Error(res.statusText);
          }
          return res.blob();
        })
        .then((blob) => {
          const objectUrl = window.URL.createObjectURL(blob);
          const pdfWindow = window.open("about:blank");
          (pdfWindow as any).location.href = objectUrl;
          (pdfWindow as any).focus();
        });
    })
    .catch((e) => {
      console.error("Unable to print the crop rating data: ", e);
      window.alert(e.message);
    });
};
