import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Dropzone from "react-dropzone";
import { filter } from "lodash-es";

import { Loading } from "../Loading/Loading";
import { AlertDialog } from "../Dialog/Dialog";
import { uploadImages } from "../../actions/image";
import { uploadFiles } from "../../actions/fileUpload";
import type { ImageFile } from "../../utils/image";
import { processImage } from "../../utils/image";
import I18n from "../../language/i18n";

import "./style.css";

const style = {};

// TODO: improve typings
type Props = any;
type State = any;

export class FieldDropzone extends Component<Props, State> {
  constructor(props: Props, context?) {
    super(props, context);
    this.state = {
      isDraggingFiles: false,
      isInProgress: false,
      isMounted: false,
      uploadStatus: {},
      rejected: null,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  uploadImage(image: ImageFile, index) {
    this.setState({
      uploadStatus: {
        ...this.state.uploadStatus,
        [index]: "uploading",
      },
    });

    return this.props.actions
      .uploadImages([image], this.props.field)
      .then(o => {
        this.setState({
          uploadStatus: {
            ...this.state.uploadStatus,
            [index]: "done",
          },
        });
      })
      .catch(o => {
        this.setState({
          uploadStatus: {
            ...this.state.uploadStatus,
            [index]: "error",
          },
        });
      });
  }

  uploadFile(file, index) {
    this.setState({
      uploadStatus: {
        ...this.state.uploadStatus,
        [index]: "uploading",
      },
    });

    return this.props.actions
      .uploadFiles([file], this.props.field)
      .then(() => {
        this.setState({
          uploadStatus: {
            ...this.state.uploadStatus,
            [index]: "done",
          },
        });
      })
      .catch(o => {
        this.setState({
          uploadStatus: {
            ...this.state.uploadStatus,
            [index]: "error",
          },
        });
      });
  }

  onDrop = (files, rejectedFiles, e) => {
    if (this.props.disabled) {
      return;
    }

    if (rejectedFiles && rejectedFiles.length) {
      this.setState({
        rejected: rejectedFiles,
      });
    }

    this.setState({
      isDraggingFiles: false,
    });

    e.stopPropagation();

    if (!files.length) {
      return;
    }

    const staged = {};

    this.setState({
      isInProgress: true,
      uploadStatus: {
        ...this.state.uploadStatus,
        ...staged,
      },
    });

    const filePromises = files.map((file, i) => {
      staged[i] = "staged";
      if (file.type === "application/pdf") {
        return Promise.resolve(file).then(f => this.uploadFile(f, i));
      }
      return Promise.resolve(file)
        .then(file =>
          processImage(file, {
            maxWidth: 1920,
            maxHeight: 1920,
            imageQuality: 0.6,
          }),
        )
        .then(image => this.uploadImage(image, i));
    });

    Promise.all(filePromises.map(p => p.catch(e => e))).then(results => {
      if (this.state.isMounted) {
        this.setState({
          isInProgress: false,
        });
      }
    });
  };

  render() {
    const { className = null, dropZoneRef = null, noLoading } = this.props;

    const showLoading = !noLoading && this.state.isInProgress;
    return (
      <Dropzone
        ref={dropZoneRef}
        // className={className}
        noClick={true}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        multiple={true}
        // disablePreview={true}
        accept="image/*,.jpg,.jpeg,.png,.pdf">
        {({ getRootProps }) => {
          return (
            <div id="field-dropzone" style={style} {...getRootProps()}>
              {this.props.children}
              {this.state.isDraggingFiles || showLoading
                ? (
                  <div className="overlay">
                    {this.state.isDraggingFiles
                      ? (
                        <div className="message">
                          {I18n.t("field.dropFilesHere")}
                        </div>
                      )
                      : null}
                    {showLoading ? <Loading /> : null}
                  </div>
                )
                : null}
              {this.state.rejected
                ? (
                  <AlertDialog
                    show={true}
                    title={I18n.t("error")}
                    children={
                      <div>
                        <p>{I18n.t("field.followingFilesAreUnsupported")}</p>
                        <ul className="list">
                          {this.state.rejected.map((file, i) => (
                            <li key={i}>{file.name}</li>
                          ))}
                        </ul>
                      </div>
                    }
                    onClose={() => this.setState({ rejected: null })}
                  />
                )
                : null}
            </div>
          );
        }}
      </Dropzone>
    );
  }

  onDragEnter = e => {
    if (
      !this.props.disabled &&
      filter(e.dataTransfer.items, i => i.kind === "file").length
    ) {
      this.setState({ isDraggingFiles: true });
    }
  };

  onDragLeave = () => this.setState({ isDraggingFiles: false });
}

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      actions: bindActionCreators(
        {
          uploadImages,
          uploadFiles,
        },
        dispatch,
      ),
    }),
  ),
)(FieldDropzone);
