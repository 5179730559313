import * as Sentry from "@sentry/react";

const dsn = process.env.REACT_APP_SENTRY_URL;
const release = `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`;
const environment = process.env.NODE_ENV || "development";

export function initializeSentry() {
  Sentry.init({
    dsn,
    release,
    environment,
    // debug: true,
  });
}

export function updateSentryUser(user: { uid: string, email: string }) {
  if (!user) {
    return;
  }
  if (!user.uid && !user.email) {
    console.error(
      "Could not add sentry user context, because user has no id or email: ",
      user,
    );
  }
  const sentryUser: Sentry.User = {};
  if (user.uid) {
    sentryUser.id = user.uid;
  }
  if (user.email) {
    sentryUser.email = user.email;
  }
  Sentry.setUser(sentryUser);
}

export function captureException(exception: any, extras?: any) {
  if (extras) {
    Sentry.withScope((scope) => {
      scope.setExtras(extras);
      Sentry.captureException(exception);
    });
  } else {
    Sentry.captureException(exception);
  }
}
