import React from "react";
import t from "tcomb-form";

import GroupFactory from "../../../../../tcomb/templates/Producer/GroupFactory";
import stylesheet from "../../../../../tcomb/stylesheets/style";

import I18n from "../../../../../language/i18n";

// Our color model
const model = () => {
  return t.struct({
    group_id: t.String,
  });
};

const options = () => {
  return {
    auto: "none",
    fields: {
      group_id: {
        label: I18n.t("producers.single"),
        factory: GroupFactory,
        config: {
          type: "producer",
          readOnly: false,
        },
      },
    },
  };
};

export default { model, options };
