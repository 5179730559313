import React, { useState, useRef } from "react";
import t from "tcomb-form";

import { NotACropState } from "farmerjoe-common/lib/flow/types";

import Dialog, { AlertDialog } from "../../../Dialog/Dialog";
import ColorForm from "./forms/ColorForm";

import I18n from "../../../../language/i18n";
import * as styleConstants from "../../../../styles/style";

import firebase from "../../../../data/firebase";

type TProps = {
  show: boolean;
  fields: {
    key: string;
    activeCrop: {
      not_a_crop: NotACropState;
      color: string;
    };
  }[];
  selectedFields: string[];
  onClose: (...args: any) => void;
};

const Form = t.form.Form;

const CropColorForm = (props: TProps) => {
  const { show, onClose, fields, selectedFields } = props;
  const [alert, setAlert] = useState({ message: null, title: null });
  const [color, setColor] = useState<{color: string}>({color: ""});
  const formRef = useRef(null);

  const targetFields = fields
    .filter((field) =>
      selectedFields.includes(field.key),
    ).filter((field) => field.activeCrop.not_a_crop === NotACropState.HarvestedCrop);

  const onSave = () => {
    if (!color.color) {
      setAlert({ message: I18n.t("crop.select_color"), title: I18n.t("error") });
      return;
    }
    const db = firebase.firestore();
    const batch = db.batch();
    targetFields.forEach((field) => {
      const fieldRef = db.collection("fields").doc(field.key);
      const { activeCrop } = field;
      activeCrop.color = color.color;
      batch.set(fieldRef, { activeCrop }, { merge: true });
    });

    batch.commit().catch((error) => {
      setAlert({ message: error.message, title: I18n.t("error") });
    });
    onClose();
  };

  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={I18n.t("crop.select_color")}
      footer={
        <div className="d-flex flex-grow-1">
          <button className="ml-auto btn btn-secondary" onClick={onClose}>
            {I18n.t("cancel")}
          </button>{" "}
          <button
            className="btn btn-primary"
            onClick={onSave}>
            {I18n.t("save")}
          </button>
        </div>
      }>
      <div style={{ flex: 1 }}>
        <div>
          <span style={{ ...styleConstants.styles.boxHeader }}></span>
          <div
            style={{
              ...styleConstants.styles.box,
              ...{ paddingLeft: 0, paddingRight: 0 },
            }}>
          </div>
        </div>
        <Form
          ref={formRef}
          type={ColorForm.model()}
          options={() => ColorForm.options()}
          value={color}
          onChange={setColor}
        />

        <AlertDialog
          show={!!alert.message}
          onClose={() =>
            setAlert({ message: null, title: null })
          }
          title={alert.title}
        >
          {alert.message}
        </AlertDialog>
      </div>
    </Dialog>
  );
};

export default CropColorForm;
