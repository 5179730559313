import React from "react";
import { map, get } from "lodash-es";

import { colorsForPicker as colors } from "farmerjoe-common/lib/utils/Colors";
import "./style.css";

import t from "tcomb-form";
import { classes } from "../../utils/dom";

const Component = t.form.Component;

class tColorPicker extends Component {
  onSelected(color) {
    this.setState({ value: color });

    // Let other props know about the new color
    if (this.props.onChange) this.props.onChange(color);
  }

  getTemplate() {
    return locals => {
      const colorSelectorStyle = styles.colorSelectorItem;

      const label = locals.label ? <span>{locals.label}</span> : null;
      const pickerColors = get(locals, "config.colors", colors);

      return (
        <div
          className={classes("color-picker", locals.hasError && "has-error")}>
          {label}
          <div>
            {map(pickerColors, (color, key) => {
              return (
                <div
                  className={classes(key === this.state.value && "selected")}
                  key={`tp-color-${key}`}
                  onClick={() => this.onSelected(key)}>
                  <div
                    style={{
                      ...colorSelectorStyle,
                      ...{ borderColor: color },
                      ...{ backgroundColor: color },
                    }}>
                    <div
                      style={{
                        width: 32,
                        height: 32,
                        position: "absolute",
                        left: 4,
                        top: 4,
                        borderRadius: 20,
                        backgroundColor: "transparent",
                        borderWidth: 3,
                        borderColor:
                          key === this.state.value ? "white" : "transparent",
                      }}></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    };
  }
}

const styles = {
  selectorHolder: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 10,
  },
  colorSelectorItem: {
    borderWidth: 1,
    width: 40,
    height: 40,
    borderRadius: 20,
    margin: 3,
    padding: 19,
  },
  selected: {
    width: 20,
    height: 20,
  },
};

export default tColorPicker;
