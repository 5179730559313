import React, { useState, useRef } from "react";
import t from "tcomb-form";

import Dialog, { AlertDialog } from "../../../Dialog/Dialog";

import I18n from "../../../../language/i18n";
import * as styleConstants from "../../../../styles/style";

import ProducerForm from "./forms/ProducerForm";

import firebase from "../../../../data/firebase";

type TProps = {
  show: boolean;
  selectedFields: string[];
  onClose: (...args: any) => void;
};

const Form = t.form.Form;

const ProducerFormContainer = (props: TProps) => {
  const { show, onClose, selectedFields } = props;
  const [alert, setAlert] = useState({ message: "", title: null });
  const [producer, setProducer] = useState<{group_id: string}>({group_id: ""});
  const formRef = useRef(null);

  const onSave = () => {
    if (!producer.group_id) {
      setAlert({ message: I18n.t("producers.select"), title: I18n.t("error") });
      return;
    }
    const db = firebase.firestore();
    const batch = db.batch();
    selectedFields.forEach((fieldId) => {
      const fieldRef = db.collection("fields").doc(fieldId);
      batch.set(fieldRef, { group_id: producer.group_id }, { merge: true });
    });

    batch.commit().catch((error) => {
      setAlert({ message: error.message, title: I18n.t("error") });
    });
    onClose();
  };

  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={I18n.t("producers.select")}
      footer={
        <div className="d-flex flex-grow-1">
          <button className="ml-auto btn btn-secondary" onClick={onClose}>
            {I18n.t("cancel")}
          </button>{" "}
          <button
            className="btn btn-primary"
            onClick={onSave}>
            {I18n.t("save")}
          </button>
        </div>
      }>
      <div style={{ flex: 1 }}>
        <div>
          <span style={{ ...styleConstants.styles.boxHeader }}></span>
          <div
            style={{
              ...styleConstants.styles.box,
              ...{ paddingLeft: 0, paddingRight: 0 },
            }}>
          </div>
        </div>
        <Form
          ref={formRef}
          type={ProducerForm.model()}
          options={() => ProducerForm.options()}
          value={producer}
          onChange={setProducer}
        />

        <AlertDialog
          show={!!alert.message}
          onClose={() =>
            setAlert({ message: "", title: null })
          }
          title={alert.title}
        >
          {alert.message}
        </AlertDialog>
      </div>
    </Dialog>
  );
};

export default ProducerFormContainer;
