import { UserObj } from "farmerjoe-common";

export const generateInvitee = (searchedUser, templateUser, inviter) => {
  const inviterUser = UserObj(inviter);
  return {
    ...templateUser,
    key: searchedUser.uid,
    name: searchedUser.firstname + " " + searchedUser.lastname,
    email: searchedUser.email,
    invited_by: inviterUser,
  };
};
