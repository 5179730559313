import React from "react";
import { get, orderBy } from "lodash-es";

import { getFieldsForPreview } from "farmerjoe-common/lib/utils/Bonitur";

import {
  supportedContentRenderers as supportedRenderers,
  NonSupportedRenderer,
} from "./ContentRenderers";

type Props = {
  formSchema: any /* FormSchema */;
  values: Record<string, any>;
  previewFor: string;
  renderedBy: "table" | "details" | "comment" | "bonitur";
};

const BoniturFormContent = (props: Props) => {
  const { values, formSchema, renderedBy, previewFor = "all" } = props;

  if (!formSchema) {
    return null;
  }

  let elements = get(formSchema, "schema.elements");
  let additionalValue: number;
  const previewFields = getFieldsForPreview(formSchema.schema, previewFor);

  elements = previewFields.map((key) => {
    const element = elements[key];
    element.className = element[`${renderedBy}Class`];
    const renderer = element.renderer;
    const value = get(values, [key], "");

    const RenderComponent =
        supportedRenderers[renderer] || NonSupportedRenderer;

    return {
      key: key,
      position: element.position,
      renderer: <RenderComponent element={element} value={value} key={key} additionalValue={additionalValue} previewFor={previewFor}/>,
    };
  });

  elements = orderBy(elements, "position");

  return (
    <div className="comment-bonitur-preview-content">
      <table className="table m-b-0">
        <tbody>
          {elements.map((element) => {
            return element.renderer;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BoniturFormContent;
