import React, { useRef } from "react";
import { classes } from "../../utils/dom";
import type { DataCellProps } from "./flow";

const DataCellV2 = (props: DataCellProps) => {
  const {
    column,
    columnClassName,
    columnIndex,
    additionalProps,
    onClick,
    style,
    row,
    selected,
  } = props;

  const ref = useRef(null);

  const value = (column.accessor as any)(row);

  return (
    <div
      className={classes(
        "cell",
        columnClassName,
        columnIndex === 0 && "cell-column-0",
        column.fixed && "fixed",
        selected && "selected",
      )}
      style={style as any}
      onClick={() => onClick && onClick(row)}
      ref={ref}>
      {column.Cell
        ? (
          column.Cell(value, row, additionalProps)
        )
        : (
          <React.Fragment>{value}</React.Fragment>
        )}
    </div>
  );
};

export default DataCellV2;
