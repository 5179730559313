import React from "react";
import {
  every,
  find,
  get,
  groupBy,
  identity,
  isEqual,
  keyBy,
  map,
  sortBy,
  throttle,
  values,
  without,
} from "lodash-es";
import { AutoSizer, MultiGrid } from "react-virtualized";
import moize from "moize";

import type { Field } from "farmerjoe-common/lib/flow/types";

import {
  DEFAULT_COLUMN_HEIGHT,
  DEFAULT_COLUMN_WIDTH,
  HEADER_ROW_COUNT,
  MIN_COLUMN_WIDTH,
} from "./constants";
import type {
  CellRenderer,
  Column,
  Sorting,
  TableProps,
  TableState,
} from "./flow";
import {
  columnTypes,
  defaultSortMethod,
  groupTypes,
  sortByDistance,
  sortByProps,
  sortByWaitTime,
} from "./columns";
import BoniturHeaderCell from "./Bonitur/HeaderCell";
import { boniturColumnIdRegex } from "./Bonitur/util";

import BoniturDataCell from "../../Table/Bonitur/DataCell";
import DataCell from "../../Table/DataCell";
import GroupCell from "../../Table/GroupCell";
import HeaderCell from "../../Table/HeaderCell";
import TableExport from "../../Table/TableExport";
import { FixedCellMeasurerCache } from "../../Table/TableCellMeasurerCache";
import { Loading } from "../../Loading/Loading";
import I18n from "../../../language/i18n";

import "./style.css";
import "../../Table/style.css";

export default class Table extends React.PureComponent<TableProps, TableState> {
  grid: any | void | null;
  startDragColumnWidth = 0;
  isResizingColumn = false;
  state = {
    showColumnConfigurator: false,
  };

  headerRowCount = HEADER_ROW_COUNT;
  boniturHeaderRow = false;
  tableExport = React.createRef<TableExport>();

  prepareColumns = (
    columnIds,
    isAdmin,
    waitTimes,
    locationPermission,
    userPosition,
    formSchemas,
    fieldsCollaborators,
    grouping,
  ) => {
    if (!isAdmin) {
      columnIds = without(columnIds, "cropAnalyses");
    }

    const additionalProps = { waitTimes, locationPermission, userPosition };
    let headerRowCount = HEADER_ROW_COUNT;
    let boniturHeaderRow = false;
    
    const columns = columnIds
      .reduce((acc, c) => {
        const columnType = columnTypes[c];
        // due to a limitation in react table we cannot access anything outside of the row data in a sort method
        // so create any methods that need additional props here
        if (c === "waitTime") {
          columnType.sortMethod = sortByWaitTime(additionalProps);
        }
        if (c === "distance") {
          columnType.sortMethod = sortByDistance(additionalProps);
        }

        if (c === "cropName" && !grouping) {
          columnType.sortMethod = sortByProps("name");
        }
        if (c === "cropArt" && !grouping) {
          columnType.sortMethod = sortByProps("art");
        }

        if (boniturColumnIdRegex.test(c)) {
          const [str, boniturId] = boniturColumnIdRegex.exec(c) as any;

          const schema = find(formSchemas, { key: boniturId });
          if (schema) {
            headerRowCount = HEADER_ROW_COUNT + 1;
            boniturHeaderRow = true;

            sortBy(
              values(schema.schema.elements),
              (element) => element.position,
            )
              .filter((element) => get(element, "options.previewInTable"))
              .forEach((element) => {
                const { name, label, labelTranslationKey, options, inputUnit } =
                    element;
                const isBoniturAge = element.name === "boniturAge";
                const header = isBoniturAge
                  ? I18n.t(labelTranslationKey)
                  : options.valueLabel || inputUnit
                    ? `${label} (${options.valueLabel || inputUnit})`
                    : label;

                acc.push({
                  accessor: (field) => {
                    return get(field, ["activeCrop", "forms", boniturId]);
                  },
                  id: `bonitur:${boniturId}:${name}`,
                  Header: header,
                  Cell: (value) => {
                    return (
                      value && (
                        <BoniturDataCell
                          schemaId={value.schema_id}
                          elementId={name}
                          values={value.values}
                          createdDate={value.created}
                          renderedBy="table"
                        />
                      )
                    );
                  },
                  sortMethod: sortByProps(`values.${name}`),
                });
              });
          }
          return acc;
        }
        if (c === "sharedWith") {
          acc.push({
            accessor: field => {
              const fieldCollaborators = get(field, "collaborators", []);
              const result = fieldCollaborators.map(c => {
                if (!fieldsCollaborators) {
                  return null;
                }
                return fieldsCollaborators.find(f => f.key === c);
              }).filter(f => f).map(f => f.name).join(", ");
              return result;
            },
            id: "sharedWith",
            Header: () => I18n.t("fieldFiltersModal.showSharedWithCompanies"),
          });
          return acc;
        }
        acc.push(columnType);
        return acc;
      }, [])
      .filter(identity); // only existing columns

    return { columns, headerRowCount, boniturHeaderRow };
  };

  getColumns() {
    const {
      waitTimes,
      locationPermission,
      userPosition,
      isAdmin,
      fieldTableState,
      formSchemas,
      fieldsCollaborators,
    } = this.props;
    const grouping = fieldTableState.grouping ?? true;
    const { columns, headerRowCount, boniturHeaderRow } = this.prepareColumns(
      fieldTableState.columnIds,
      isAdmin,
      waitTimes,
      locationPermission,
      userPosition,
      formSchemas,
      fieldsCollaborators,
      grouping,
    );
    this.headerRowCount = headerRowCount;
    this.boniturHeaderRow = boniturHeaderRow;
    return columns;
  }

  prepareExportColumns = moize(
    function prepareExportColumns(columns) {
      return columns.reduce((acc, column, i) => {
        if (column.textColumns) {
          acc.splice(
            acc.length,
            0,
            ...column.textColumns().map((c) => ({
              Cell: () => null,
              accessor: column.accessor,
              ...c,
            })),
          );
        } else {
          acc.push(column);
        }
        return acc;
      }, []);
    },
    {
      maxSize: 1,
    },
  );

  getExportColumns() {
    const columns = this.getColumns();
    return this.prepareExportColumns(columns);
  }

  sortAndGroupFields = moize(
    function sortFields(sorting: Sorting, columns, fields, grouping) {
      const columnsById = keyBy(columns, "id");

      if (!grouping) {
        const { id: sortingColumnId, desc: sortingColumnDesc } = sorting[0];
        const column = columnsById[sortingColumnId];
        const sortMethod = column.sortMethod || defaultSortMethod; // default sort method asc/ desc
        return fields.sort((a, b) =>
          sortMethod(
            column.accessor(a),
            column.accessor(b),
            sortingColumnDesc, // ascending true/false
          ),
        );
      }

      const cropNameSorting = sorting[0];
      const groupedFields = groupBy(fields, groupTypes.crop.keyAccessor); // activeCropName -- activeCropColor -- false
      let secondLevelSort = (fields) => fields;

      if (sorting.length > 1) {
        const { id: sortingColumnId, desc: sortingColumnDesc } = sorting[1];
        const column = columnsById[sortingColumnId];
        if (column) {
          const sortMethod = column.sortMethod || defaultSortMethod; // default sort method asc/ desc
          secondLevelSort = (fields) =>
            fields.sort((a, b) =>
              sortMethod(
                column.accessor(a),
                column.accessor(b),
                sortingColumnDesc, // ascending true/false
              ),
            );
        }
      }

      const groups = map(groupedFields, (fields, key) => {
        const sorted = secondLevelSort(fields);
        const group = sorted.reduce(
          (result, field) => {
            Object.assign(result, groupTypes.crop.props(field, result));
            return result;
          },
          {
            _group: "crop",
          },
        );
        group.key = key;
        group.fields = sorted;
        return group;
      });

      const sortedGroups = groups.sort((a, b) =>
        groupTypes.crop.sortMethod(a, b, cropNameSorting.desc),
      );

      return sortedGroups.reduce((rows, group) => {
        rows.push(group);
        rows.splice(rows.length, 0, ...group.fields);
        return rows;
      }, []);
    },
    {
      maxSize: 1,
      matchesKey: (newArgs, oldArgs) => {
        return (
          newArgs[2] === oldArgs[2] &&
          isEqual(newArgs[0], oldArgs[0]) &&
          isEqual(newArgs[1], oldArgs[1])
        );
      },
    },
  );

  getRows() {
    const { fields } = this.props;
    const grouping = this.props.fieldTableState.grouping ?? true;

    const sorting = this.getSorting();
    return this.sortAndGroupFields(sorting, this.getColumns(), fields, grouping);
  }

  prepareExportRows = moize(
    function prepareExportRows(rows) {
      return rows.filter((row) => !row._group);
    },
    {
      maxSize: 1,
    },
  );

  getExportRows() {
    const rows = this.getRows();
    return this.prepareExportRows(rows);
  }

  sorting = moize(
    function sorting(sortingArray, grouping, columnsArray): Sorting {
      const columns = keyBy(columnsArray, "id");
      sortingArray = sortingArray || [];

      // remove any sorting columns that don't exist in the current columns
      if (!every(sortingArray, (s) => columns[s.id])) {
        sortingArray = sortingArray.filter((s) => columns[s.id]);
      }
      if (!sortingArray.length) {
        // shouldn't happen
        sortingArray = [{ id: "cropName", desc: false }];
      }
      if (!grouping) {
        return sortingArray;
      }
      if (sortingArray[0].id !== "cropName") {
        sortingArray = [
          {
            id: "cropName",
            desc: false,
          },
          ...sortingArray,
        ];
      }

      return sortingArray;
    },
    {
      maxSize: 1,
      isDeepEqual: true,
    },
  );

  getSorting() {
    const { fieldTableState } = this.props;
    const { sorting } = fieldTableState;
    const grouping = fieldTableState.grouping ?? true;
    return this.sorting(sorting, grouping, this.getColumns());
  }

  sortingIndexed = moize((sorting) => keyBy(sorting, "id"), {
    maxSize: 1,
  });

  getSortingIndexed() {
    return this.sortingIndexed(this.getSorting());
  }

  additionalProps = moize(
    (waitTimes, locationPermission, userPosition) => ({
      waitTimes,
      locationPermission,
      userPosition,
    }),
    {
      maxSize: 1,
    },
  );

  getAdditionalProps() {
    const { waitTimes, locationPermission, userPosition } = this.props;
    return this.additionalProps(waitTimes, locationPermission, userPosition);
  }

  sizeCache = new FixedCellMeasurerCache({
    defaultHeight: DEFAULT_COLUMN_HEIGHT,
    defaultWidth: DEFAULT_COLUMN_WIDTH,
    fixedHeight: true,
    fixedWidth: true,
    columnWidths: this.getColumnWidths(),
    keyMapper: (rowIndex, columnIndex) => {
      if (rowIndex < this.headerRowCount) {
        return "header-" + rowIndex + "-" + columnIndex;
      } else {
        const row = this.getRows()[rowIndex - this.headerRowCount];
        if (row) {
          return row.key + "--" + columnIndex;
        } else {
          return rowIndex + "--" + columnIndex;
        }
      }
    },
  });

  getColumnWidths() {
    return this.getColumns().map(
      (c) =>
        get(this.props.fieldTableState, `columnWidths.${c.id}`) ||
        this.getDefaultColumnWidth(c),
    );
  }

  getDefaultColumnWidth(column) {
    if (column.id === "fertilizing") {
      return 400;
    }
    if (column.id === "rowIndex") {
      return 50;
    }
    return DEFAULT_COLUMN_WIDTH;
  }

  componentDidUpdate(prevProps: TableProps) {
    if (
      !isEqual(
        get(this.props, "fieldTableState.columnIds"),
        get(prevProps, "fieldTableState.columnIds"),
      ) ||
      !isEqual(
        get(this.props, "fieldTableState.columnWidths"),
        get(prevProps, "fieldTableState.columnWidths"),
      ) ||
      !isEqual(get(this.props, "formSchemas"), get(prevProps, "formSchemas"))
    ) {
      this.sizeCache.setWidths(this.getColumnWidths());
    }

    if (
      this.grid &&
      (this.props.fields !== prevProps.fields ||
        !isEqual(
          get(this.props, "fieldTableState.sorting"),
          get(prevProps, "fieldTableState.sorting"),
        ) ||
        !isEqual(
          get(this.props, "fieldTableState.columnWidths"),
          get(prevProps, "fieldTableState.columnWidths"),
        ) ||
        !isEqual(
          get(this.props, "fieldTableState.columnIds"),
          get(prevProps, "fieldTableState.columnIds"),
        ) ||
        !isEqual(get(this.props, "formSchemas"), get(prevProps, "formSchemas")))
    ) {
      this.grid.recomputeGridSize();
    }
  }

  render() {
    const { loading, emptyView, ...restProps } = this.props;

    if (loading) {
      return <Loading />;
    }

    const rows = this.getRows();

    if (!rows) {
      return null;
    }

    if (rows.length === 0) {
      return emptyView;
    }

    const columnCount = this.getColumns().length;
    const rowCount = rows.length + this.headerRowCount;

    return (
      <div className="table-container">
        <AutoSizer key={0}>
          {({ width, height }) => (
            <MultiGrid
              ref={(el) => (this.grid = el)}
              cellRenderer={this.renderCell}
              columnCount={columnCount}
              rowCount={rowCount}
              columnWidth={this.sizeCache.columnWidth}
              rowHeight={(this.sizeCache as any).rowHeight}
              width={width}
              height={height}
              fixedRowCount={this.headerRowCount}
              hideTopRightGridScrollbar
              // for updating the table when some prop changes
              {...restProps}
            />
          )}
        </AutoSizer>
        <TableExport
          ref={this.tableExport}
          cellRenderer={this.renderExportCell}
          fileName={this.props.exportFileName as any}
        />
      </div>
    );
  }

  resizeColumnStart(columnIndex: number) {
    this.startDragColumnWidth = this.sizeCache.columnWidth({
      index: columnIndex,
    });
    this.isResizingColumn = true;
  }

  resizeColumn(deltaX: number, index: number) {
    let columnWidth = this.startDragColumnWidth;
    columnWidth = Math.max(MIN_COLUMN_WIDTH, columnWidth + deltaX);

    this.sizeCache.setWidth(index, columnWidth);

    const columnWidths = {
      ...this.props.fieldTableState.columnWidths,
      [this.getColumns()[index].id]: columnWidth,
    };
    this.sizeCache.setWidths(this.getColumns().map((c) => columnWidths[c.id]));

    this.throttledRecomputeGridSizes();
  }

  throttledRecomputeGridSizes = throttle(() => {
    this.grid.recomputeGridSize();
  }, 16);

  resizeColumnStop(deltaX: number, index: number) {
    setTimeout(() => (this.isResizingColumn = false), 0);

    let columnWidth = this.startDragColumnWidth;
    columnWidth = Math.max(MIN_COLUMN_WIDTH, columnWidth + deltaX);

    this.props.onTableStateChange({
      ...this.props.fieldTableState,
      columnWidths: {
        ...this.props.fieldTableState.columnWidths,
        [this.getColumns()[index].id]: columnWidth,
      },
    });

    // update row sizes
    (this.sizeCache as any).clearAll();
    this.grid.measureAllCells();
  }

  changeSorting(columnId: string, desc: boolean) {
    const { fieldTableState } = this.props;
    const grouping = fieldTableState.grouping ?? true;

    if (!grouping) {
      const sorting = [
        {
          id: columnId,
          desc: desc,
        },
      ];
      this.props.onTableStateChange({
        ...this.props.fieldTableState,
        sorting,
      });
      return;
    }


    let sorting;
    if (columnId === "cropName") {
      sorting = [
        {
          id: "cropName",
          desc: desc,
        },
        ...this.props.fieldTableState.sorting.filter(
          (s) => s.id !== "cropName",
        ),
      ];
    } else {
      let cropNameSorting = find(
        this.props.fieldTableState.sorting,
        (s) => s.id === "cropName",
      );
      if (!cropNameSorting) {
        cropNameSorting = {
          id: "cropName",
          desc: false,
        };
      }
      sorting = [
        cropNameSorting,
        {
          id: columnId,
          desc: desc,
        },
      ];
    }
    
    this.props.onTableStateChange({
      ...this.props.fieldTableState,
      sorting,
    });
  }

  renderCell = (options: CellRenderer) => {
    return this._renderCell(options, this.getColumns(), this.getRows());
  };

  renderExportCell = (options: CellRenderer) => {
    return this._renderCell(
      options,
      this.getExportColumns(),
      this.getExportRows(),
    );
  };

  _renderCell(
    { columnIndex, key, rowIndex, style }: CellRenderer,
    columns: Column[],
    rows: Record<string, any>[],
  ) {
    const { openFieldId } = this.props;
    const column = columns[columnIndex];
    const row = rows[rowIndex - this.headerRowCount];
    const additionalProps = this.getAdditionalProps();
    const sortingIndexed = this.getSortingIndexed();
    const columnClassName = `column-${column.id}`;

    let cell;
    if (rowIndex >= this.headerRowCount && row._group) {
      cell = (
        <GroupCell
          key={key}
          column={column}
          columnClassName={columnClassName}
          columnIndex={columnIndex}
          style={style}
          row={row}
          isLastColumn={columnIndex === columns.length - 1}
        />
      );
    } else if (rowIndex < this.headerRowCount - 1 && this.boniturHeaderRow) {
      const match = boniturColumnIdRegex.exec(column.id);
      if (match) {
        const [str1, boniturId] = match;
        let renderBoniturHeader = false;
        if (columnIndex === 0) {
          renderBoniturHeader = true;
        } else {
          const previousColumnMatch = boniturColumnIdRegex.exec(
            columns[columnIndex - 1].id,
          );
          if (!previousColumnMatch) {
            renderBoniturHeader = true;
          } else {
            const [str2, previousBoniturId] = previousColumnMatch;
            if (previousBoniturId !== boniturId) {
              renderBoniturHeader = true;
            }
          }
        }

        if (renderBoniturHeader) {
          const schema = find(this.props.formSchemas, { key: boniturId });
          if (schema) {
            const boniturColumnCount = values(schema.schema.elements).filter(
              (element) => get(element, "options.previewInTable"),
            ).length;

            let width = 0;
            for (let i = 0; i < boniturColumnCount; i++) {
              width += this.sizeCache.getWidth(rowIndex, columnIndex + i);
            }

            cell = (
              <BoniturHeaderCell
                schema={schema}
                width={width}
                key={key}
                columnClassName={columnClassName}
                columnIndex={columnIndex}
                style={style}
              />
            );
          }
        }
      }
    } else if (rowIndex < this.headerRowCount) {
      cell = (
        <HeaderCell
          key={key}
          column={column}
          columnClassName={columnClassName}
          columnIndex={columnIndex}
          onClick={this.onHeaderClick}
          onStartDrag={this.headerOnStartDrag}
          onDrag={this.headerOnDrag}
          onStopDrag={this.headerOnStopDrag}
          style={style}
          sortingIndexed={sortingIndexed}
        />
      );
    } else {
      const selected = this.props.activeMultiSelect 
        ? (this.props.selectedFields as Array<string>).includes(row.key)
        : openFieldId === row.key;
      cell = (
        <DataCell
          key={key}
          row={row}
          column={column}
          columnClassName={columnClassName}
          columnIndex={columnIndex}
          additionalProps={additionalProps}
          onClick={this.onDataCellClick}
          style={style}
          selected={selected}
        />
      );
    }

    return cell || null;
  }

  onDataCellClick = (field: Field) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(field.key, field.activeCrop.key);
    }
  };

  onHeaderClick = (column: Column) => {
    if (!this.isResizingColumn && column.sort !== false) {
      const sorting = this.getSorting();
      const existingSorting = find(sorting, (s) => s.id === column.id);
      let desc = false;
      if (existingSorting) {
        desc = !existingSorting.desc;
      }
      this.changeSorting(column.id, desc);
    }
  };

  headerOnStartDrag = (column: Column, columnIndex: number) =>
    this.resizeColumnStart(columnIndex);

  headerOnDrag = (x: number, column: Column, columnIndex: number) =>
    this.resizeColumn(x, columnIndex);

  headerOnStopDrag = (x: number, column: Column, columnIndex: number) =>
    this.resizeColumnStop(x, columnIndex);

  exportData() {
    if (this.tableExport.current) {
      this.tableExport.current.exportData(
        this.getExportColumns().length,
        this.getExportRows().length + this.headerRowCount,
      );
    }
  }
}
