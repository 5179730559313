import React from "react";
import Modal from "react-bootstrap-modal";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { editForm } from "farmerjoe-common/lib/actions/form";

import FormSelectBonitur from "./FormSelectBonitur";
import CreateBoniturForm from "./CreateBoniturForm";
import { classes } from "../../utils/dom";
import type { Analysis } from "../../flowTypes";

// prevent the clicks from bubbling outside of the Dialog, this causes problems
const stopPropagation = e => e.stopPropagation();

type Props = {
  show: boolean;
  actions: {
    editForm: typeof editForm;
  };
  onCancel: (...args: Array<any>) => any;
};

type State = {
  history: Array<Record<string, any>>;
  analysis?: Analysis | null;
};

class NewBoniturDialog extends React.Component<Props, State> {
  constructor(props: Props, context?) {
    super(props, context);
    this.state = {
      history: [this.makeViewObject("FormSelectBonitur")],
      analysis: null,
    };
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.editForm(null);
  }

  makeViewObject(view: string, props?: any) {
    const newView = {
      view,
      props: {
        ...props,
        goBack: this.goBack.bind(this),
        openView: this.openView.bind(this),
        saveState: state => (newView.props.state = state),
      },
    };
    return newView;
  }

  openView(view: string, props: any) {
    this.setState({
      history: [...this.state.history, this.makeViewObject(view, props)],
    });
  }

  goBack(props: Record<string, any> | null | undefined) {
    const history = this.state.history.slice(0, -1);

    if (props) {
      const previousView = history[history.length - 1];
      previousView.props = { ...previousView.props, ...props };
    }

    this.setState({
      history,
    });
  }

  getCurrentView() {
    const currentView = this.state.history[this.state.history.length - 1];
    const { view, props } = currentView;
    let config: any = null;

    switch (view) {
    case "FormSelectBonitur":
      config = {
        el: <FormSelectBonitur {...props} />,
        title: "Bonitur",
      };
      break;
    case "CreateBoniturForm":
      config = {
        el: (
          <CreateBoniturForm
            {...props}
            onClose={this.close.bind(this)}
            goBack={this.goBack.bind(this)}
          />
        ),
      };
      break;
    default:
      throw new Error(`view ${view} is not defined`);
    }

    config.view = view;
    return config;
  }

  render() {
    const { show } = this.props;
    const { title, el, view } = this.getCurrentView();

    return (
      <Modal
        show={show}
        dialogClassName={classes(
          "new-bonitur-dialog",
          view === "CreateBoniturForm" && "form",
        )}
        onHide={this.close.bind(this)}
        enforceFocus={false}
        backdrop="static">
        {title
          ? (
            <Modal.Header closeButton={false}>
              <Modal.Title onClick={stopPropagation}>{title}</Modal.Title>
              <button className="close" onClick={this.close.bind(this)}>
                <i className="fa fa-times" />
              </button>
            </Modal.Header>
          )
          : null}
        {el}
      </Modal>
    );
  }

  close() {
    const { onCancel } = this.props;

    this.setState({
      history: [this.makeViewObject("FormSelectBonitur")],
      analysis: null,
    });

    onCancel && onCancel(null);
  }
}

export default compose(
  connect(
    state => state,
    (dispatch) => {
      return {
        actions: bindActionCreators(
          Object.assign(
            {},
            {
              editForm,
            },
          ),
          dispatch,
        ),
      };
    },
  ),
)(NewBoniturDialog);
