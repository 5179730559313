import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import "firebase/firestore";
import Modal from "react-bootstrap-modal";
import { Form } from "@flipbyte/formik-json";
import { get, intersection } from "lodash-es";
import shortid from "shortid32";

import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import { UserObj } from "farmerjoe-common/lib/utils/User";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import {
  getFormSchemaQuery,
  getFormQuery,
} from "farmerjoe-common/lib/utils/firestoreRedux/Forms";
import {
  getOpenFormSchema,
  getFormSchema,
  getForm,
  getEditFormId,
} from "farmerjoe-common/lib/selectors/forms";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";

import { firebaseToFormikJSON } from "./firebaseToFormikJSON";
import Position from "./Position";
import Icon from "../Common/Icon";
import withRouter from "../Router/withRouter";
import { Loading } from "../Loading/Loading";
import Dialog, { AlertConfirmDialog, AlertDialog } from "../Dialog/Dialog";
import I18n from "../../language/i18n";
import type { LatLng } from "../../flowTypes";
import { FJMUTED, FJWHITE, FJBROWN } from "../../styles/style";
import fire from "../../data/firebase";

const DIALOG_CONFIRM_VALUE = "yes";

type Props = {
  // function used to close the modal
  onClose: (...args: Array<any>) => any;
  // Function used to go back to the selection of ratings
  goBack?: (...args: Array<any>) => any;
  show: boolean;
  openCompany: string;
  openField: string;
  auth: any; // Profile
  schemaId: string;
  editMode: boolean;
  editedFormData?: any;
  browsingGroup: string;
  formSchema: any;
  firebase: any;
  loaded: boolean;
};

type State = {
  alertTitle: null | string;
  alertMessage: null | string;
  showPositionConfirmDialog: boolean;
  ignorePosition: boolean;
  position: null | LatLng;
  showTooltipDropDown: boolean;
  showDeleteConfirmDialog: boolean;
};

// prevent the clicks from bubbling outside of the Dialog, this causes problems
const stopPropagation = (e) => e.stopPropagation();

class NoFieldBoniturForm extends React.PureComponent<Props, State> {
  ref: React.RefObject<any> | null = null;

  state = {
    alertTitle: null,
    alertMessage: null,
    position: null,
    showPositionConfirmDialog: false,
    ignorePosition: false,
    showTooltipDropDown: false,
    showDeleteConfirmDialog: false,
    cropRatingId: shortid.generate(),
  };

  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.deleteBonitur = this.deleteBonitur.bind(this);
    this.ref = React.createRef();
  }

  onSave(values) {
    const {
      firebase,
      openCompany,
      browsingGroup,
      onClose,
      auth,
      schemaId,
      editMode,
      editedFormData,
      formSchema,
    } = this.props;
    
    const schema = this.getSchema();
    const schemaDefaultValues = this.getSchemaDefaultValues(schema);
    const { position } = this.state;
    const db = firebase.firestore();
    const batch = db.batch();
    const currentTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    let formRef = db.collection("formSubmissions").doc();

    if (editMode && editedFormData) {
      formRef = db.collection("formSubmissions").doc(editedFormData.key);

      batch.update(formRef, {
        modified: firebase.firestore.FieldValue.serverTimestamp(),
        modified_by: UserObj(auth),
        formValues: {
          ...schemaDefaultValues,
          ...values,
        },
      });
      batch.commit();
      onClose();
      return;
    }

    batch.set(formRef, {
      key: formRef.id,
      schema_id: schemaId,
      company_id: openCompany,
      group_id: browsingGroup,
      created: currentTimestamp,
      created_by: UserObj(auth),
      type: "bonitur",
      formValues: {
        ...schemaDefaultValues,
        ...values,
      },
      position: position,
    });
    batch.commit();
    onClose();
  }

  deleteBonitur() {
    this.setState({ showDeleteConfirmDialog: true });
  }

  submitForm = () => {
    const form = this.ref?.current;

    if (form) {
      const { editMode } = this.props;
      const { position, ignorePosition } = this.state;
      const { values } = form.state;
      const { formSchema } = this.props;
      const schema = this.getSchema();

      if (!editMode && position === null && !ignorePosition) {
        this.setState({ showPositionConfirmDialog: true });
        return;
      }

      // Validate the form
      form
        .runValidationSchema(values)
        // Now find out if we have errors in fields related to images
        // the Image object can only contain url and ref, but if hte image is still uploading
        // we set loading: true - this sets the field as invalid and we can display our alert here
        .then((valid) => {
          const elements = formSchema.schema.elements;

          const imageKeys = Object.keys(schema.elements).filter((key) => {
            const element = elements[key];

            if (element.renderer === "fj-image") {
              return true;
            }

            return false;
          });

          const issueWithImage = intersection(Object.keys(valid), imageKeys);

          if (issueWithImage.length) {
            this.setState({
              alertMessage: I18n.t("imageIsRequiredOrIsBeingUploaded"),
              alertTitle: "",
            });
          }

          form && form.submitForm();
        })
        .catch(() => {
          // error doesn't matter - submit the form again
          form && form.submitForm();
        });
    }
  };

  modalHeader() {
    const { onClose, formSchema } = this.props;
    const { showTooltipDropDown } = this.state;
    const title = `Bonitur ${formSchema.name}`;
    return (
      <div style={{ position: "relative" }}>
        <Modal.Header closeButton={false} key="header">
          <div>
            <Modal.Title onClick={stopPropagation}>{title}</Modal.Title>
          </div>
          <div
            onClick={() => {
              this.setState({ showTooltipDropDown: !showTooltipDropDown });
            }}
          >
            <Icon
              key="info"
              name={"ios-information-circle-outline"}
              style={{
                fontSize: 18,
                marginLeft: 10,
                color: FJMUTED,
              }}
            />
          </div>
          <button className="close" onClick={onClose}>
            <i className="fa fa-times" />
          </button>
        </Modal.Header>
        {showTooltipDropDown && (
          <div
            style={{
              position: "absolute",
              zIndex: 1000,
              backgroundColor: FJWHITE,
              width: "100%",
              padding: "12px 14px 15px",
              color: FJBROWN,
              fontSize: "16px",
              lineHeight: "32px",
            }}
          >
            <div className={"d-flex justify-content-between"}>
              <span>Boniturname:</span>
              <span>{formSchema.name}</span>
            </div>
            <div className={"d-flex justify-content-between"}>
              <span>Bonitur-ID:</span>
              <span>{formSchema.typeId}</span>
            </div>
          </div>
        )}
      </div>
    );
  }

  getSchema() {
    const { formSchema, openCompany } = this.props;

    if (get(formSchema, "schema")) {
      return firebaseToFormikJSON(formSchema.schema, { companyId: openCompany});
    }

    return null;
  }

  render() {
    const schema = this.getSchema();
    const formValues = this.getFormValues();

    return this.renderForm(
      <Form
        ref={this.ref}
        schema={schema}
        onSubmit={this.onSave}
        enableReinitialize={true}
        initialValues={formValues}
        companyId={this.props.openCompany}
      />,
    );
  }

  renderForm(content) {
    const { loaded, show, onClose, editMode } = this.props;
    const schema = this.getSchema();

    if (editMode) {
      if (!schema || !loaded) {
        return null;
      }

      return (
        <Dialog
          show={show}
          onClose={onClose}
          backdrop="static"
          className={"new-bonitur-dialog"}
          title={"Bonitur bearbeiten"}
          footer={this.renderFooter()}
        >
          <div style={{ flex: 1 }}>
            {content}
            <AlertDialog
              show={!!this.state.alertMessage}
              onClose={() =>
                this.setState({ alertMessage: null, alertTitle: null })
              }
              title={this.state.alertTitle}
            >
              {this.state.alertMessage}
            </AlertDialog>
            {this.renderNoPositionConfirmDialog()}
            {this.renderDeleteConfirmDialog()}
          </div>
        </Dialog>
      );
    }

    if (!loaded || !schema) {
      return [
        <Modal.Body onClick={(e) => e.stopPropagation()} key="body">
          <Loading />
        </Modal.Body>,
      ];
    }

    return [
      this.modalHeader(),
      <Modal.Body onClick={(e) => e.stopPropagation()} key="body">
        {content}
        {this.renderPosition()}
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
        >
          {this.state.alertMessage}
        </AlertDialog>
        {this.renderNoPositionConfirmDialog()}
      </Modal.Body>,
      <Modal.Footer onClick={(e) => e.stopPropagation()} key="footer">
        {this.renderFooter()}
      </Modal.Footer>,
    ];
  }

  renderNoPositionConfirmDialog() {
    return (
      <AlertConfirmDialog
        show={this.state.showPositionConfirmDialog}
        title={"Standort noch nicht ermittelt"}
        buttons={[
          { value: "no", label: I18n.t("cancel"), className: "btn-secondary" },
          {
            value: DIALOG_CONFIRM_VALUE,
            label: "Trotzdem speichern",
            className: "btn-primary",
          },
        ]}
        onClose={(reason) => {
          this.setState({ showPositionConfirmDialog: false });
          if (reason === DIALOG_CONFIRM_VALUE) {
            this.setState({ ignorePosition: true }, () => {
              this.submitForm();
            });
          } else {
            // this.reallyGoBack()
          }
        }}
      >
        Standort noch nicht ermittelt
      </AlertConfirmDialog>
    );
  }

  renderPosition() {
    return (
      <div className="form-group">
        <label htmlFor="image" className="">
          <Icon
            name={"map-marker-alt"}
            iconType={"fal"}
            style={{ color: FJMUTED, paddingRight: 5, fontSize: 14 }}
          />
          {I18n.t("position")}
        </label>
        <Position
          onChange={(position) => {
            this.setState({ position: position });
          }}
        />
      </div>
    );
  }

  renderDeleteConfirmDialog() {
    return (
      <AlertConfirmDialog
        show={this.state.showDeleteConfirmDialog}
        title={I18n.t("bonitur.deleteDialogueTitle")}
        buttons={[
          { label: I18n.t("cancel"), value: "cancel" },
          {
            label: I18n.t("yes"),
            value: DIALOG_CONFIRM_VALUE,
            className: "btn-danger",
          },
        ]}
        onClose={(reason) => {
          this.setState({ showDeleteConfirmDialog: false });
          if (reason === DIALOG_CONFIRM_VALUE) {
            this.setState({ ignorePosition: true }, () => {
              return fire
                .firestore()
                .collection("formSubmissions")
                .doc(this.props.editedFormData.key)
                .delete();
            });
          }
        }}
      >
        {I18n.t("bonitur.deleteDialogueBody")}
      </AlertConfirmDialog>
    );
  }

  private getFormValues() {
    const { editedFormData, formSchema } = this.props;

    if (!formSchema) {
      return {};
    }

    const formValues = get(editedFormData, "formValues", {});
    const {
      schema: { elements },
    } = formSchema;

    if (elements) {
      Object.keys(elements)
        .map((key) => ({ key, renderer: elements[key].renderer }))
        .forEach((pair) => {
          if (pair.renderer === "fj-unique-id") {
            const value = this.state.cropRatingId;
            if (!formValues[pair.key]) formValues[pair.key] = value;
          }
        });
    }

    return { ...formValues };
  }

  private getSchemaDefaultValues(schema) {
    const schemaDefaultValues = {};
    if (!schema) {
      return schemaDefaultValues;
    }

    for (const element in schema.elements) {
      if (schema.elements[element].hasOwnProperty("defaultValue")) {
        schemaDefaultValues[element] = schema.elements[element].defaultValue;
      }
    }

    return schemaDefaultValues;
  }

  renderFooter() {
    const { editMode } = this.props;
    return (
      <div className="d-flex flex-grow-1">
        {editMode ? (
          <button
            className="btn btn-danger-bordered"
            onClick={this.deleteBonitur}
          >
            {I18n.t("delete")}
          </button>
        ) : null}{" "}
        <button className="ml-auto btn btn-primary" onClick={this.submitForm}>
          {I18n.t("save")}
        </button>
      </div>
    );
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const editFormId = getEditFormId(state);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  let schemaId: any = null;
  let editedFormData: any = null;

  if (editFormId) {
    editedFormData = getForm(state, editFormId);
    schemaId = get(editedFormData, "schema_id");
  } else {
    schemaId = getOpenFormSchema(state);
  }

  const formSchema = getFormSchema(state, schemaId);

  const checkLoaded: any[] = [];
  if (schemaId) {
    checkLoaded.push(getFormSchemaQuery(schemaId));
  }

  if (editFormId) {
    checkLoaded.push(getFormQuery(editFormId));
  }

  const loaded = hasLoaded(checkLoaded, state);

  return {
    openCompany,
    editFormId,
    editMode: !!editFormId,
    browsingGroup,
    auth: state.firebase.profile,
    myCompanyProfile,
    formSchema,
    loaded,
    schemaId,
    editedFormData,
  };
};

export default compose(
  connect(selector),
  firestoreConnect((props) => {
    const { schemaId, editFormId } = props;
    const paths: any = [];

    if (editFormId) {
      paths.push(getFormQuery(editFormId));
    }

    if (schemaId) {
      paths.push(getFormSchemaQuery(schemaId));
    }
    return paths;
  }),
  withRouter,
)(NoFieldBoniturForm);
