import React, { useState } from "react";
import Modal from "react-bootstrap-modal";

import ModalSearchUser from "./Modals/ModalSearchUser";
import ModalProfile from "./Modals/ModalProfile";
import ModalFailure from "./Modals/ModalFailure";
import ModalSuccess from "./Modals/ModalSuccess";

import I18n from "../../language/i18n";
import { GlobalUserSearchProps } from "./types";

const stopPropagation = e => e.stopPropagation();

const GlobalUserSearch = (props: GlobalUserSearchProps) => {
  const { show, onClose, groupType } = props;
  const [ currentDialog, setCurrentDialog ] = useState("INIT");
  const [ users, setUsers ] = useState([] as any);
  const [ selectedUser, setSelectedUser ] = useState(null as any);
  const [ searchValue, setSearchValue ] = useState("");

  return (
    <Modal
      show={show}
      dialogClassName="employees-search-dialog"
      onHide={onClose}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title onClick={stopPropagation}>{I18n.t("globalEmployeeSearch")}</Modal.Title>
        <button className="close" onClick={onClose}>
          <i className="fa fa-times" />
        </button>
      </Modal.Header>
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        { currentDialog === "INIT" && ModalSearchUser({ users, setUsers, setSelectedUser, setCurrentDialog, searchValue, setSearchValue }) }
        { currentDialog === "PROFILE" && (<ModalProfile selectedUser={selectedUser} setCurrentDialog={setCurrentDialog} groupType={groupType}/>) }
        { currentDialog === "SUCCESS" && ModalSuccess({ onClose }) }
        { currentDialog === "FAILURE" && ModalFailure({ onClose }) }
      </Modal.Body>
    </Modal>
  );
};

export default GlobalUserSearch;
