import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";

import Icon from "../Common/Icon";
import I18n from "../../language/i18n";
import Dialog from "../Dialog/Dialog";
import { Loading } from "../Loading/Loading";

import { FJAPPLEGREEN, FJAPPLERED } from "../../styles/style";
import { uploadFiles } from "../../actions/importFields";
import { captureException } from "../../utils/sentry";


const ImportFields = (props) => {
  const { show, onClose, actions, openCompany } = props;
  const [ uploading, setUploading ] = useState(false);
  const [ files, setFiles ] = useState<any>([]);
  const [ isFinished, setIsFinished ] = useState(false);
  const [ success, setSuccess ] = useState(false);

  const save = () => {
    if (!files.length) {
      return;
    }

    setUploading(true);
    return actions.uploadFiles(files, openCompany).catch((e) => {
      captureException(new Error("Fields import error: "), e);
      setSuccess(false);
    }).then(() => {
      setSuccess(true);
      console.log("done");
    }).finally(() => {
      setUploading(false);
      setIsFinished(true);
    });
  };

  const onFileChange = (e) => {
    setFiles([...files, ...e.target.files]);
  };

  const filesSummary = files.length ? (
    <div>
      <p>
        {I18n.t("fields.selectedFiles")}
      </p>
      <ul style={{listStyleType: "none", padding: 0, margin: 0}}>
        {files.map((file, i) => (
          <li key={i}>{file.name}</li>
        ))}
      </ul>
    </div>
  ) : null;

  const footer = uploading ? null : isFinished ? (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    }}>
      <button
        className="btn btn-primary"
        onClick={onClose}
      >
        {I18n.t("ok")}
      </button>
    </div>
  ) : (
    <>
      <button
        className="ml-auto btn btn-secondary"
        onClick={onClose}
      >
        {I18n.t("cancel")}
      </button>{" "}
      <button
        className="btn btn-primary"
        onClick={() => save()}
      >
        {I18n.t("save")}
      </button>
    </>
  );

  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={I18n.t("fields.importFields")}
      footer={footer}
      className="form create-field-dialog"
    >
      {isFinished ? <Response success={success} /> : (
        <div style={{ flex: 1, flexGrow: 1 }}>
          <div style={{ flex: 1, flexGrow: 1 }}>
            <p>
              {I18n.t("fields.importFieldsHintPrimary")}
            </p>
            <p>
              <small>{I18n.t("fields.importFieldsHintSecondary")}</small>
            </p>
            {uploading ? <Loading /> : (
              <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                  <InputTypeFileBtn onChange={onFileChange} />
                </div>
                {filesSummary}
              </>
            )}
          </div>
        </div>
      )}
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          uploadFiles,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state) => {
  const openCompany = getOpenCompanyId(state);
  return {
    openCompany,
  };
};

export default connect(selector, mapDispatchToProps)(ImportFields);

const InputTypeFileBtn = ({ onChange }) => {
  return (
    <>
      <label className="btn btn-primary">
        <input 
          type="file"
          multiple
          accept=".kml, .zip"
          onChange={onChange}
          className="btn btn-primary" 
          style={{ display: "none" }}
        />
        {I18n.t("fields.uploadFiles")}
      </label>
    </>
  );
};

const Response = ({ success }) => {
  return (
    <div
      style={{textAlign: "center"}}
    >
      {success ? (
        <>
          <Icon
            name={"check-circle"}
            iconType={"fa"}
            light
            style={{ color: FJAPPLEGREEN, fontSize: 150 }}
          />

          <div
            style={{
              padding: 10,
            }}>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                textAlign: "center",
              }}>
              {I18n.t("billing.success")}
            </div>
          </div>
        </>
      ) : (
        <>
          <Icon
            name={"times-circle"}
            iconType={"fa"}
            light
            style={{ color: FJAPPLERED, fontSize: 150 }}
          />

          <div
            style={{
              padding: 10,
            }}>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                textAlign: "center",
              }}>
              {I18n.t("error")}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
