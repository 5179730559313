import { dummy } from "../utils/Comment";
import { get } from "lodash-es";
import type { Field } from "../flowTypes";

export const uploadFiles = (files, field: Field) => {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
    getFirebase: (...args: Array<any>) => any,
  ) => {
    const state = getState();
    const firebase = getFirebase();
    const profile = get(state, "firebase.profile");

    const promises = files.map((file) => {
      const db = firebase.firestore();
      const cRef = db.collection("comments").doc();

      const comment = {
        ...dummy(
          cRef.id,
          field.company_id,
          field,
          field.activeCrop,
          profile,
          "user.file",
        ),
        text: file.name,
      };

      return cRef.set(comment).then(() => {
        const fileStoragePath = `files/${field.company_id}/${field.key}/${file.name}`;

        const storageRef = firebase
          .storage()
          .ref()
          .child(fileStoragePath);

        return storageRef
          .put(file)
          .then(() => storageRef.getDownloadURL())
          .then(downloadURL => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                cRef
                  .update("file.uri", downloadURL, "extraData.file.ref", {
                    bucket: storageRef.bucket,
                    path: storageRef.fullPath,
                  })
                  .then(resolve, reject);
              }, 200);
            });
          })
          .catch(err => console.error("error uploading file", err));
      });
    });

    return Promise.all(promises);
  };
};
